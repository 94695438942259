import Vuelidate from '@vuelidate/core';
import type { Validation } from '@vuelidate/core';
import { defineNuxtPlugin } from '#app';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    v$: Validation;
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Vuelidate);
});
