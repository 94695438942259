import { default as _91bankProductCommissionId_93ivyMa3d1VCMeta } from "/app/pages/admin/bank_product_commissions/[bankProductCommissionId].vue?macro=true";
import { default as indexOYqyWVwSSvMeta } from "/app/pages/admin/bank_product_commissions/index.vue?macro=true";
import { default as _91bankProductId_93jUw7ECqVmhMeta } from "/app/pages/admin/bank_products/[bankProductId].vue?macro=true";
import { default as indexf1du6Nst9wMeta } from "/app/pages/admin/bank_products/index.vue?macro=true";
import { default as _91bankId_93yAuGDego75Meta } from "/app/pages/admin/banks/[bankId].vue?macro=true";
import { default as indexFFHKPF5J3UMeta } from "/app/pages/admin/banks/index.vue?macro=true";
import { default as _91brandId_93YMLKPvJjm9Meta } from "/app/pages/admin/brands/[brandId].vue?macro=true";
import { default as indexTvbfPptajvMeta } from "/app/pages/admin/brands/index.vue?macro=true";
import { default as _91cashRequirementGoalId_933IajBORKleMeta } from "/app/pages/admin/cash_requirement_goal/[cashRequirementGoalId].vue?macro=true";
import { default as indexdmJSYsmn97Meta } from "/app/pages/admin/cash_requirement_goal/index.vue?macro=true";
import { default as _91ChargeTypeId_93wSoyqjkSKBMeta } from "/app/pages/admin/charge_types/[ChargeTypeId].vue?macro=true";
import { default as indexYsLtYQ1YhhMeta } from "/app/pages/admin/charge_types/index.vue?macro=true";
import { default as _91ContactEventTypeId_93DxmV8c4RzfMeta } from "/app/pages/admin/contact_event_types/[ContactEventTypeId].vue?macro=true";
import { default as indexuRX1itJKs7Meta } from "/app/pages/admin/contact_event_types/index.vue?macro=true";
import { default as _91contactPositionId_93wgdanpOuaqMeta } from "/app/pages/admin/contact_positions/[contactPositionId].vue?macro=true";
import { default as indexE8n9yeDNPzMeta } from "/app/pages/admin/contact_positions/index.vue?macro=true";
import { default as _91creditTypeId_93And9pJyTe4Meta } from "/app/pages/admin/credit_types/[creditTypeId].vue?macro=true";
import { default as indexJMPPO0tfL6Meta } from "/app/pages/admin/credit_types/index.vue?macro=true";
import { default as _91excludedDocumentId_93CNiz1Zn7QsMeta } from "/app/pages/admin/excluded_documents/[excludedDocumentId].vue?macro=true";
import { default as index1KJQPVVYcxMeta } from "/app/pages/admin/excluded_documents/index.vue?macro=true";
import { default as _91incomeTypeId_9342BlkZlbJDMeta } from "/app/pages/admin/income_types/[incomeTypeId].vue?macro=true";
import { default as indexOIxk36DqsPMeta } from "/app/pages/admin/income_types/index.vue?macro=true";
import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as _91insuranceTypeId_93HRL1LJ8BXdMeta } from "/app/pages/admin/insurance_types/[insuranceTypeId].vue?macro=true";
import { default as index9B2aqTCVfzMeta } from "/app/pages/admin/insurance_types/index.vue?macro=true";
import { default as _91legalCapacityId_93WojUMYqERSMeta } from "/app/pages/admin/legal_capacities/[legalCapacityId].vue?macro=true";
import { default as index4MwdNLDT9iMeta } from "/app/pages/admin/legal_capacities/index.vue?macro=true";
import { default as _91positionId_93bL7R5IjFR3Meta } from "/app/pages/admin/positions/[positionId].vue?macro=true";
import { default as indexZyb8HIwTyWMeta } from "/app/pages/admin/positions/index.vue?macro=true";
import { default as agenda1WncwhWOayMeta } from "/app/pages/agenda.vue?macro=true";
import { default as editOH38McPs0bMeta } from "/app/pages/commerce/contacts/[contactId]/edit.vue?macro=true";
import { default as previewxis6kRItWAMeta } from "/app/pages/commerce/contacts/[contactId]/preview.vue?macro=true";
import { default as relationsdFbJbmsJcGMeta } from "/app/pages/commerce/contacts/[contactId]/relations.vue?macro=true";
import { default as sites7Ot0oVy4ySMeta } from "/app/pages/commerce/contacts/[contactId]/sites.vue?macro=true";
import { default as _91contactId_93d4piAKg6oFMeta } from "/app/pages/commerce/contacts/[contactId].vue?macro=true";
import { default as indexKDzYPy26TcMeta } from "/app/pages/commerce/contacts/index.vue?macro=true";
import { default as contactsqr6s2jsJwFMeta } from "/app/pages/commerce/sites/[siteId]/contacts.vue?macro=true";
import { default as edit5EBQDVK9HCMeta } from "/app/pages/commerce/sites/[siteId]/edit.vue?macro=true";
import { default as previewsidSKnuxVLMeta } from "/app/pages/commerce/sites/[siteId]/preview.vue?macro=true";
import { default as relationsTbZ7fnUO48Meta } from "/app/pages/commerce/sites/[siteId]/relations.vue?macro=true";
import { default as _91siteId_93hWutSNlmtsMeta } from "/app/pages/commerce/sites/[siteId].vue?macro=true";
import { default as indexMWUW5NJyv6Meta } from "/app/pages/commerce/sites/index.vue?macro=true";
import { default as commercefg3abYeh4wMeta } from "/app/pages/commerce.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as notifications883AI3QFtaMeta } from "/app/pages/notifications.vue?macro=true";
import { default as _91bankAccountId_933EHiqyS8D1Meta } from "/app/pages/opportunities/[opportunityRefId]/analysis/bank-accounts/[bankAccountId].vue?macro=true";
import { default as _91bankAccountStatementId_93daV4zN34ZxMeta } from "/app/pages/opportunities/[opportunityRefId]/analysis/document/[documentId]/bank-account-statements/[bankAccountStatementId].vue?macro=true";
import { default as indexkn6Rd4d98QMeta } from "/app/pages/opportunities/[opportunityRefId]/analysis/index.vue?macro=true";
import { default as analysissu3ggoNbjQMeta } from "/app/pages/opportunities/[opportunityRefId]/analysis.vue?macro=true";
import { default as allgNXTMqoUJrMeta } from "/app/pages/opportunities/[opportunityRefId]/documents/all.vue?macro=true";
import { default as deletedPn7ODro5KcMeta } from "/app/pages/opportunities/[opportunityRefId]/documents/deleted.vue?macro=true";
import { default as documentsyC5X92TwUFMeta } from "/app/pages/opportunities/[opportunityRefId]/documents.vue?macro=true";
import { default as editxwA4AqvYYqMeta } from "/app/pages/opportunities/[opportunityRefId]/edit.vue?macro=true";
import { default as historyQdTAdXT4C3Meta } from "/app/pages/opportunities/[opportunityRefId]/history.vue?macro=true";
import { default as deletedqjEoy6eAWuMeta } from "/app/pages/opportunities/[opportunityRefId]/messages/deleted.vue?macro=true";
import { default as generatedrZJPdPTQu2Meta } from "/app/pages/opportunities/[opportunityRefId]/messages/generated.vue?macro=true";
import { default as receivedxDERkKRodxMeta } from "/app/pages/opportunities/[opportunityRefId]/messages/received.vue?macro=true";
import { default as messagesJMqPWUoWJrMeta } from "/app/pages/opportunities/[opportunityRefId]/messages.vue?macro=true";
import { default as previewcBTOreAbqdMeta } from "/app/pages/opportunities/[opportunityRefId]/preview.vue?macro=true";
import { default as advancedBn4E8IQTNdMeta } from "/app/pages/opportunities/[opportunityRefId]/simulation/advanced.vue?macro=true";
import { default as autonUhny3OTjmMeta } from "/app/pages/opportunities/[opportunityRefId]/simulation/auto.vue?macro=true";
import { default as _91simulationLegacyId_93Im7XCyWN7uMeta } from "/app/pages/opportunities/[opportunityRefId]/simulation/blink/[simulationLegacyId].vue?macro=true";
import { default as srmIJcUveQpJ4Meta } from "/app/pages/opportunities/[opportunityRefId]/simulation/srm.vue?macro=true";
import { default as simulationUaR7zAcBaQMeta } from "/app/pages/opportunities/[opportunityRefId]/simulation.vue?macro=true";
import { default as _91opportunityRefId_93ITw1smMGkTMeta } from "/app/pages/opportunities/[opportunityRefId].vue?macro=true";
import { default as index2kNRumbLUUMeta } from "/app/pages/opportunities/index.vue?macro=true";
import { default as reportingox84ohMv4kMeta } from "/app/pages/reporting.vue?macro=true";
import { default as index98zCyyEGM5Meta } from "/app/pages/settings/notifications/index.vue?macro=true";
import { default as tasksFuIRQ3HwhDMeta } from "/app/pages/tasks.vue?macro=true";
export default [
  {
    name: "admin-bank_product_commissions-bankProductCommissionId",
    path: "/admin/bank_product_commissions/:bankProductCommissionId()",
    component: () => import("/app/pages/admin/bank_product_commissions/[bankProductCommissionId].vue")
  },
  {
    name: "admin-bank_product_commissions",
    path: "/admin/bank_product_commissions",
    component: () => import("/app/pages/admin/bank_product_commissions/index.vue")
  },
  {
    name: "admin-bank_products-bankProductId",
    path: "/admin/bank_products/:bankProductId()",
    component: () => import("/app/pages/admin/bank_products/[bankProductId].vue")
  },
  {
    name: "admin-bank_products",
    path: "/admin/bank_products",
    component: () => import("/app/pages/admin/bank_products/index.vue")
  },
  {
    name: "admin-banks-bankId",
    path: "/admin/banks/:bankId()",
    component: () => import("/app/pages/admin/banks/[bankId].vue")
  },
  {
    name: "admin-banks",
    path: "/admin/banks",
    component: () => import("/app/pages/admin/banks/index.vue")
  },
  {
    name: "admin-brands-brandId",
    path: "/admin/brands/:brandId()",
    component: () => import("/app/pages/admin/brands/[brandId].vue")
  },
  {
    name: "admin-brands",
    path: "/admin/brands",
    component: () => import("/app/pages/admin/brands/index.vue")
  },
  {
    name: "admin-cash_requirement_goal-cashRequirementGoalId",
    path: "/admin/cash_requirement_goal/:cashRequirementGoalId()",
    component: () => import("/app/pages/admin/cash_requirement_goal/[cashRequirementGoalId].vue")
  },
  {
    name: "admin-cash_requirement_goal",
    path: "/admin/cash_requirement_goal",
    component: () => import("/app/pages/admin/cash_requirement_goal/index.vue")
  },
  {
    name: "admin-charge_types-ChargeTypeId",
    path: "/admin/charge_types/:ChargeTypeId()",
    component: () => import("/app/pages/admin/charge_types/[ChargeTypeId].vue")
  },
  {
    name: "admin-charge_types",
    path: "/admin/charge_types",
    component: () => import("/app/pages/admin/charge_types/index.vue")
  },
  {
    name: "admin-contact_event_types-ContactEventTypeId",
    path: "/admin/contact_event_types/:ContactEventTypeId()",
    component: () => import("/app/pages/admin/contact_event_types/[ContactEventTypeId].vue")
  },
  {
    name: "admin-contact_event_types",
    path: "/admin/contact_event_types",
    component: () => import("/app/pages/admin/contact_event_types/index.vue")
  },
  {
    name: "admin-contact_positions-contactPositionId",
    path: "/admin/contact_positions/:contactPositionId()",
    component: () => import("/app/pages/admin/contact_positions/[contactPositionId].vue")
  },
  {
    name: "admin-contact_positions",
    path: "/admin/contact_positions",
    component: () => import("/app/pages/admin/contact_positions/index.vue")
  },
  {
    name: "admin-credit_types-creditTypeId",
    path: "/admin/credit_types/:creditTypeId()",
    component: () => import("/app/pages/admin/credit_types/[creditTypeId].vue")
  },
  {
    name: "admin-credit_types",
    path: "/admin/credit_types",
    component: () => import("/app/pages/admin/credit_types/index.vue")
  },
  {
    name: "admin-excluded_documents-excludedDocumentId",
    path: "/admin/excluded_documents/:excludedDocumentId()",
    component: () => import("/app/pages/admin/excluded_documents/[excludedDocumentId].vue")
  },
  {
    name: "admin-excluded_documents",
    path: "/admin/excluded_documents",
    component: () => import("/app/pages/admin/excluded_documents/index.vue")
  },
  {
    name: "admin-income_types-incomeTypeId",
    path: "/admin/income_types/:incomeTypeId()",
    component: () => import("/app/pages/admin/income_types/[incomeTypeId].vue")
  },
  {
    name: "admin-income_types",
    path: "/admin/income_types",
    component: () => import("/app/pages/admin/income_types/index.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/app/pages/admin/index.vue")
  },
  {
    name: "admin-insurance_types-insuranceTypeId",
    path: "/admin/insurance_types/:insuranceTypeId()",
    component: () => import("/app/pages/admin/insurance_types/[insuranceTypeId].vue")
  },
  {
    name: "admin-insurance_types",
    path: "/admin/insurance_types",
    component: () => import("/app/pages/admin/insurance_types/index.vue")
  },
  {
    name: "admin-legal_capacities-legalCapacityId",
    path: "/admin/legal_capacities/:legalCapacityId()",
    component: () => import("/app/pages/admin/legal_capacities/[legalCapacityId].vue")
  },
  {
    name: "admin-legal_capacities",
    path: "/admin/legal_capacities",
    component: () => import("/app/pages/admin/legal_capacities/index.vue")
  },
  {
    name: "admin-positions-positionId",
    path: "/admin/positions/:positionId()",
    component: () => import("/app/pages/admin/positions/[positionId].vue")
  },
  {
    name: "admin-positions",
    path: "/admin/positions",
    component: () => import("/app/pages/admin/positions/index.vue")
  },
  {
    name: "agenda",
    path: "/agenda",
    component: () => import("/app/pages/agenda.vue")
  },
  {
    name: "commerce",
    path: "/commerce",
    component: () => import("/app/pages/commerce.vue"),
    children: [
  {
    name: "commerce-contacts-contactId",
    path: "contacts/:contactId()",
    component: () => import("/app/pages/commerce/contacts/[contactId].vue"),
    children: [
  {
    name: "commerce-contacts-contactId-edit",
    path: "edit",
    component: () => import("/app/pages/commerce/contacts/[contactId]/edit.vue")
  },
  {
    name: "commerce-contacts-contactId-preview",
    path: "preview",
    component: () => import("/app/pages/commerce/contacts/[contactId]/preview.vue")
  },
  {
    name: "commerce-contacts-contactId-relations",
    path: "relations",
    component: () => import("/app/pages/commerce/contacts/[contactId]/relations.vue")
  },
  {
    name: "commerce-contacts-contactId-sites",
    path: "sites",
    component: () => import("/app/pages/commerce/contacts/[contactId]/sites.vue")
  }
]
  },
  {
    name: "commerce-contacts",
    path: "contacts",
    component: () => import("/app/pages/commerce/contacts/index.vue")
  },
  {
    name: "commerce-sites-siteId",
    path: "sites/:siteId()",
    component: () => import("/app/pages/commerce/sites/[siteId].vue"),
    children: [
  {
    name: "commerce-sites-siteId-contacts",
    path: "contacts",
    component: () => import("/app/pages/commerce/sites/[siteId]/contacts.vue")
  },
  {
    name: "commerce-sites-siteId-edit",
    path: "edit",
    component: () => import("/app/pages/commerce/sites/[siteId]/edit.vue")
  },
  {
    name: "commerce-sites-siteId-preview",
    path: "preview",
    component: () => import("/app/pages/commerce/sites/[siteId]/preview.vue")
  },
  {
    name: "commerce-sites-siteId-relations",
    path: "relations",
    component: () => import("/app/pages/commerce/sites/[siteId]/relations.vue")
  }
]
  },
  {
    name: "commerce-sites",
    path: "sites",
    component: () => import("/app/pages/commerce/sites/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    component: () => import("/app/pages/notifications.vue")
  },
  {
    name: "opportunities-opportunityRefId",
    path: "/opportunities/:opportunityRefId()",
    component: () => import("/app/pages/opportunities/[opportunityRefId].vue"),
    children: [
  {
    name: analysissu3ggoNbjQMeta?.name,
    path: "analysis",
    component: () => import("/app/pages/opportunities/[opportunityRefId]/analysis.vue"),
    children: [
  {
    name: "opportunities-opportunityRefId-analysis-bank-accounts-bankAccountId",
    path: "bank-accounts/:bankAccountId()",
    component: () => import("/app/pages/opportunities/[opportunityRefId]/analysis/bank-accounts/[bankAccountId].vue")
  },
  {
    name: "opportunities-opportunityRefId-analysis-document-documentId-bank-account-statements-bankAccountStatementId",
    path: "document/:documentId()/bank-account-statements/:bankAccountStatementId()",
    component: () => import("/app/pages/opportunities/[opportunityRefId]/analysis/document/[documentId]/bank-account-statements/[bankAccountStatementId].vue")
  },
  {
    name: "opportunities-opportunityRefId-analysis",
    path: "",
    component: () => import("/app/pages/opportunities/[opportunityRefId]/analysis/index.vue")
  }
]
  },
  {
    name: "opportunities-opportunityRefId-documents",
    path: "documents",
    component: () => import("/app/pages/opportunities/[opportunityRefId]/documents.vue"),
    children: [
  {
    name: "opportunities-opportunityRefId-documents-all",
    path: "all",
    component: () => import("/app/pages/opportunities/[opportunityRefId]/documents/all.vue")
  },
  {
    name: "opportunities-opportunityRefId-documents-deleted",
    path: "deleted",
    component: () => import("/app/pages/opportunities/[opportunityRefId]/documents/deleted.vue")
  }
]
  },
  {
    name: "opportunities-opportunityRefId-edit",
    path: "edit",
    component: () => import("/app/pages/opportunities/[opportunityRefId]/edit.vue")
  },
  {
    name: "opportunities-opportunityRefId-history",
    path: "history",
    component: () => import("/app/pages/opportunities/[opportunityRefId]/history.vue")
  },
  {
    name: "opportunities-opportunityRefId-messages",
    path: "messages",
    component: () => import("/app/pages/opportunities/[opportunityRefId]/messages.vue"),
    children: [
  {
    name: "opportunities-opportunityRefId-messages-deleted",
    path: "deleted",
    component: () => import("/app/pages/opportunities/[opportunityRefId]/messages/deleted.vue")
  },
  {
    name: "opportunities-opportunityRefId-messages-generated",
    path: "generated",
    component: () => import("/app/pages/opportunities/[opportunityRefId]/messages/generated.vue")
  },
  {
    name: "opportunities-opportunityRefId-messages-received",
    path: "received",
    component: () => import("/app/pages/opportunities/[opportunityRefId]/messages/received.vue")
  }
]
  },
  {
    name: "opportunities-opportunityRefId-preview",
    path: "preview",
    component: () => import("/app/pages/opportunities/[opportunityRefId]/preview.vue")
  },
  {
    name: "opportunities-opportunityRefId-simulation",
    path: "simulation",
    component: () => import("/app/pages/opportunities/[opportunityRefId]/simulation.vue"),
    children: [
  {
    name: "opportunities-opportunityRefId-simulation-advanced",
    path: "advanced",
    component: () => import("/app/pages/opportunities/[opportunityRefId]/simulation/advanced.vue")
  },
  {
    name: "opportunities-opportunityRefId-simulation-auto",
    path: "auto",
    component: () => import("/app/pages/opportunities/[opportunityRefId]/simulation/auto.vue")
  },
  {
    name: "opportunities-opportunityRefId-simulation-blink-simulationLegacyId",
    path: "blink/:simulationLegacyId()",
    component: () => import("/app/pages/opportunities/[opportunityRefId]/simulation/blink/[simulationLegacyId].vue")
  },
  {
    name: "opportunities-opportunityRefId-simulation-srm",
    path: "srm",
    component: () => import("/app/pages/opportunities/[opportunityRefId]/simulation/srm.vue")
  }
]
  }
]
  },
  {
    name: "opportunities",
    path: "/opportunities",
    component: () => import("/app/pages/opportunities/index.vue")
  },
  {
    name: "reporting",
    path: "/reporting",
    component: () => import("/app/pages/reporting.vue")
  },
  {
    name: "settings-notifications",
    path: "/settings/notifications",
    component: () => import("/app/pages/settings/notifications/index.vue")
  },
  {
    name: "tasks",
    path: "/tasks",
    component: () => import("/app/pages/tasks.vue")
  }
]